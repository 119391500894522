import React, {useRef, useEffect} from 'react'
import Layout from "../../../components/layouts"
import gsap from "gsap"

function ScratchCards() {

    const scratchOne = useRef(null)
    const scratchTwo = useRef(null)
    const scratchThree = useRef(null)


    // useEffect(() => {
    //     gsap.to(scratchOne.current, {
    //         rotation: 5,
    //         delay: 1,
    //         yoyo: true,
    //         delay: 1,
    //         repeat: -1,
    //         ease: "power4.out"
    //     })

    //   });

  
    return (
        <>
        <Layout>
        <div className="scratch-body">

          

            <div className="scratch_holder">   
            <img ref={scratchOne} className="scratch-one scard"  src="/landing/scratch1.png" />
            <img ref={scratchTwo} className="scratch-two scard"  src="/landing/scratch3.png" />
            <img ref={scratchThree} className="scratch-three scard"  src="/landing/scratch2.png" />
            </div>

            <div className="scratch_header_text" style={{maxWidth: '550px'}}>
            {/* <h2>Workhub</h2> */}
            <h1>Scratch Cards</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus aliquam nisi sapien, in eleifend justo viverra laoreet.</p>
            </div>
            
           

        </div>
        </Layout>
        </>
    )
}

export default ScratchCards
